import React from "react";
import { Nav } from "react-bootstrap";
import { ChevronRight, ChevronDown } from 'react-bootstrap-icons';
import Api from './../../api.js';
import Close from "./../deck/common/icons/close";
import { Link } from "react-router-dom";


class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      minimal: props.minimal,
      toc: {},
      withinEid: null,
      selectedCids: [],
      isReachUsOpen: false,
      match: props.match,
      search: props.search,
      history: props.history,
      anchor: null,
      contactOpen: false,
      shareOpen: false,
      loginOpen: false,
      user: null,
      link: { text: null },
      selectedCats: [],
      menu: props.menu
    };

    this.env = props.env;
    this.api = new Api();
    this.domain = this.api.getDomain();
    this.subdomain = this.api.getSubdomain();
    this.exConfig = null;
    this.api.getExhibitConfig().then((res) => {
      const c = res.data[this.domain];
      if (this.subdomain && this.subdomain !== '' && c[this.subdomain]) {
        this.exConfig = c[this.subdomain].exhibition;
      } else {
        this.exConfig = c.exhibition;
      }
      this.excluded = this.exConfig.landingPage;
    })
    this.queue = [];
  }

  componentDidMount = () => {
    if (this.state.anchor === null) {
      this.setState({ anchor: this.ref });
    }
  }

  componentDidUpdate = () => {
    const props = this.props;
    // user clicks on home or closes article
    if (
      (this.state.selectedCids.length !== props.selectedArticleIds.length) ||
      (this.state.selectedCids[0] !== props.selectedArticleIds[0])) { // second context is selected from a different exhibit
      this.setState({
        selectedCids: [...this.props.selectedArticleIds],
        withinEid: this.props.withinExhibitId,
        link: props.link
      })
    }
    if (
      this.props.link.text !== this.state.link.text) {
      // ASSUMPTION: A link is opened from landing page
      // or by clicking on article link in another article.
      const char = this.props.link.text;
      this.setState({ link: { text: char, keepOpen: this.props.link.keepOpen } });
      if (char !== null) {
        if (char.indexOf("http") > -1) {
          window.open(char, "_blank");
        } else { // open card beside
          const parts = char.split("|");
          this.getMatch(parts);
        }
      }
    }
    if (!this.isEqual(this.state.menu, props.menu)) {
      this.setState({ menu: props.menu });
      const m = props.menu.map((e) => {
        return this.fetchToc(e.exhibitionId)
      })
      Promise.all(m).then(res => {
        if (this.state.search === true) {
          const parts = this.state.match.params.segment.split("/").filter(s => s !== "");
          this.getMatch(parts);
        }
      })
    }
    if (this.state.minimal !== props.minimal) {
      this.setState({ minimal: props.minimal });
    }
    if (this.props.match.params.segment !== this.state.match.params.segment) {
      this.setState({ match: this.props.match });
      if (this.props.match.params.segment) {
        const parts = this.props.match.params.segment.split("/").filter(s => s !== "");
        this.getMatch(parts);
      }
    }
  }

  isEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    else {
      if (arr1.length > 0) {
        if (arr1[0].name === arr2[0].name) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }

  getMatch = (parts) => {
    const category = parts[0]; // find exhibit with topmost category
    let exID, exName;
    this.state.menu.forEach(m => {
      const arr = [...this.state.toc[m.exhibitionId]];
      while (arr.length > 0) {
        const content = arr.pop();
        if (content.url === category || content.item.data.classification === category
          || "cls-" + category === content.url) {
          exID = m.exhibitionId;
          exName = m.name
          this.setState((state) => {
            return { ...state, withinEid: m.exhibitionId }
          });
          arr.length = 0;
        }
      }
    });
    setTimeout(() => {
      if (exID) {
        this.searchTocItem(this.state.toc[exID], parts, exID, exName, parts.join("/"));
      } else {
        window.history.replaceState(null, window.document.title, "/");
      }
    }, 10) // Had to place this inside settimeout as state.withinEid doesn't get updated
  }

  searchTocItem = (children, catName, exID, breadcrumb = "", url) => {
    this.queue.push(...children);
    while (this.queue.length > 0) {
      const c = this.queue.shift();
      if (c.item.type === "Classification" && (c.url === "cls-" + catName[0] ||
        c.item.data.classification === catName[0] ||
        c.url === catName[0])) {
        this.queue.length = 0;
        catName.shift();
        if (catName.length) {
          this.searchTocItem(c.children, catName, exID, breadcrumb + " / " + c.item.data.classification, url);
        } else {
          const cIds = this.getContainingArticles(c.children);
          this.selectGroup(cIds, breadcrumb + " / " + c.item.data.classification, exID, null, c, "/" + url)
        }
        this.setState({ selectedCats: [c.url] });
      } else if (c.item.type === "Content" && (c.url === "cnt-" + catName[0] ||
        c.url === catName[0] ||
        c.item.data.title === catName[0])) {
        this.queue.length = 0;
        catName.shift();
        this.selectArticle(c.item.data.contentId.data.valueCellId, exID);
      }
      else {
        this.searchTocItem(c.children, catName, exID, breadcrumb, url);
      }
    }
  }

  fetchToc = (exhibitionId) => {
    return this.api.getToc(exhibitionId, this.props.user && this.props.user.authToken)
      .then((response) => {
        const toc = { ...this.state.toc };
        toc[exhibitionId] = response.data;
        this.setState({ toc: toc })
      })
  }

  toggleSidebar = () => {
    this.props.toggleSidebarStateClass();
  }

  selectArticle = (selectedArticleId, eId, noScroll) => {
    this.setState({ selectedCats: [] });
    let cIds;
    if (this.state.withinEid !== eId) {
      cIds = [];
    } else {
      cIds = [...this.state.selectedCids];
    }
    if (this.isSelected(selectedArticleId)) {
      cIds.splice(cIds.indexOf(selectedArticleId), 1);
      if (this.props.link.keepOpen === true) {
        cIds.push(selectedArticleId);
      }
    } else {
      cIds.push(selectedArticleId);
    }
    this.props.selectArticle(cIds, eId, noScroll);
  }

  selectGroup = (cIdArray, breadcrumb, eId, e, toc, url) => {
    if (e) {
      const els = document.getElementsByClassName("nav-link");
      [].forEach.call(els, function (el) { el.style.textDecoration = "none" });
      const els2 = document.getElementsByClassName("menu-title");
      [].forEach.call(els2, function (el) { el.style.textDecoration = "none" });
      e.currentTarget.style.textDecoration = "underline";
      e.stopPropagation();
    }
    this.setState({ withinEid: eId, selectedCats: [breadcrumb] });
    this.props.selectCategory(cIdArray, eId, breadcrumb, toc, null, url);
  }

  selectExhibit = (e, eId) => {
    const els = document.getElementsByClassName("nav-link");
    [].forEach.call(els, function (el) { el.style.textDecoration = "none" });
    const els2 = document.getElementsByClassName("menu-title");
    [].forEach.call(els2, function (el) { el.style.textDecoration = "none" });
    e.currentTarget.style.textDecoration = "underline";
    this.setState({ selectedEid: eId })
    this.props.selectExhibit(eId);
  }

  isSelected = (cId) => {
    return this.state.selectedCids.some((id) => id === cId);
  }

  getContainingArticles = (children) => {
    const cIdGroup = children.map((c) => {
      const contentID = c.item.data.contentId;
      return contentID ? contentID.data.valueCellId : this.getContainingArticles(c.children)
    }).flat();
    return cIdGroup;
  }

  renderToc = (arr, level, eId, parentbreadcrumb = "", url = "") => {
    if (!arr) return '';
    return (
      arr.map((obj, index) => {
        const cId = obj.item.data.contentId ? obj.item.data.contentId.data.valueCellId : obj.url;
        const selectableGroup = true;
        const collapsed = false;
        return (
          <Nav.Item key={cId} className={`toc-item level-${level}`}
          >
            {(obj.item.type === "Classification") &&
              (obj.item.data.classification.toLowerCase() !== this.exConfig.constellation.toLowerCase()) &&
              <Link className={collapsed === true ? "collapsed" : ""} to={"/search" + url + "/" + obj.url}>
                {!selectableGroup && <ChevronRight
                  className="icon-collapsed" />}
                {!selectableGroup && <ChevronDown
                  className="icon-expanded"
                />}
                <span className="menu-title"
                >{obj.item.data.classification}</span>

              </Link>}
            {(obj.item.type === "Classification") &&
              (obj.item.data.classification.toLowerCase() !== this.exConfig.constellation.toLowerCase()) &&
              this.renderToc(obj.children, level + 1, eId, parentbreadcrumb + " / " + obj.item.data.classification, url + "/" + obj.url)
            }
            {index === arr.length}
          </Nav.Item>
        )
      }))
  }

  toggleCollapser = (e) => {
    const el = e.currentTarget;
    const elem = el.classList.contains("nav-link") ? el : el.parentElement;
    elem.classList.contains("collapsed") ?
      elem.classList.remove("collapsed") :
      elem.classList.add("collapsed");
    e.stopPropagation();
  }

  openCollapser = (e) => {
    const el = e.currentTarget;
    const elem = el.classList.contains("nav-link") ? el : el.parentElement;
    elem.classList.remove("collapsed");
    e.stopPropagation();
  }

  updateContactState = (isOpen) => {
    if (isOpen === true) {
      this.setState({
        shareOpen: false,
        loginOpen: false
      });
    }
    this.setState({ contactOpen: isOpen });
  }

  updateShareState = (isOpen) => {
    if (isOpen === true) {
      this.setState({
        contactOpen: false,
        loginOpen: false
      });
    }
    this.setState({ shareOpen: isOpen });
  }

  // updateLoginState = (isOpen) => {
  //   if (isOpen === true) {
  //     this.setState({
  //       contactOpen: false,
  //       shareOpen: false
  //     });
  //   }
  //   this.setState({ loginOpen: isOpen });
  // }

  render() {

    if (!this.state.menu) return '';
    let filtered = this.state.menu.filter(m => m.show);
    if (window.origin.indexOf("herokuapp") && window.showNonNavigable === true) {
      filtered = this.state.menu;
    }
    const navItems = filtered.map((i, index) =>
      <Nav.Item key={i.exhibitionId} className="menu-item">
        {!this.exConfig.landingPage ?
          <Nav.Link onClick={((e) => this.selectExhibit(e, i.exhibitionId))} className="pub-title">
            {i.name}</Nav.Link> :
          <span className="pub-title nav-link disabled">{i.name}</span>
        }
        {this.renderToc(this.state.toc[i.exhibitionId], 1, i.exhibitionId, i.name)}
        <hr className="separator"></hr>
      </Nav.Item>
    )
    const sidebarMax = (
      <Nav className={`col-xs-1 sidebar sidebar-max`}>
        {<Nav.Link onClick={this.toggleSidebar} className="close-sidebar" key="close"><Close /></Nav.Link>}
        <div className="menu-container">
          {this.env.locations && <Nav.Item className="menu-item" key="locations">
            <Nav.Link className="pub-title">Location</Nav.Link>
            <Nav.Item className="toc-item level-1" key="Europe"><Nav.Link><span className="menu-title">Europe</span></Nav.Link></Nav.Item>
            {this.env.locations.split(",").map(loc => <Nav.Item className="disabled toc-item level-1" key={loc}><Nav.Link>
              <span className="menu-title">{loc}</span>
            </Nav.Link></Nav.Item>
            )}
          </Nav.Item>
          }
          {navItems}
        </div>
        <div className="address-container">
          <p>{this.env.metaInfo.addressTitle}</p>
          {this.env.metaInfo.address.split("\n").map(a => <p key={a}>{a}</p>)}
          {this.env.metaInfo.kvk && this.env.metaInfo.kvk.split("\n").map(t => <p key={t}>{t}</p>)}
          <p className="pt-10 pb-10">{this.env.metaInfo.copyright.split(",")[0].replace("© 2021", "© " + new Date().getFullYear())}</p>
        </div>
      </Nav>
    )

    if (!this.state.minimal) {
      return <div className='sidebar-container'>
        {sidebarMax}
      </div>
    } else {
      return '';
    }
  }
};


export default Sidebar;